<template>
  <div>
    <b-card title="Filtros">
      <div>
        <b-form-checkbox @change="swtichAllowInactivate" v-model="allowInactivates" name="check-button" switch>
          Mostrar inativos?
        </b-form-checkbox>
      </div>
      <div
        :style="{
          overflowX: 'auto',
          width: '100%',
          display: 'flex',
          marginBottom: '20px',
          border: '1px solid',
          borderColor: '#d5d5d5',
          borderRadius: '4px',
          paddingLeft: '5px'
        }"
      >
        <div
          :style="{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            alignItems: 'center'
          }"
          v-for="user in users"
          :key="user.id"
        >
          <input type="checkbox" @change="getTasksBySelect(user.member_id)" checked />
          <span
            class="cutom-bullet"
            @click="handleClickBullet(user)"
            :style="{
              borderRadius: '50%',
              display: 'block',
              color: user.color,
              background: user.color,
              width: '15px',
              height: '15px'
            }"
          ></span>
          <div
            :style="{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center'
            }"
          >
            <p
              :style="{
                marginRight: '10px',
                marginTop: '12px'
              }"
            >
              {{ user.username }}
            </p>
          </div>
        </div>
      </div>
      <b-row class="mb-2 align-items-center">
        <!-- INTERVALO -->
        <b-col cols="4">
          <!-- start_date -->
          <b-form-group label="Data de Início">
            <b-form-input v-model="start_date" type="month" @change="updateDates" />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <!-- end_date -->
          <b-form-group label="Data de Fim">
            <b-form-input v-model="end_date" type="month" @change="updateDates" />
          </b-form-group>
        </b-col>

        <b-col cols="4" class="d-flex align-items-center mt-1">
          <b-button variant="primary" @click="getTasks" class="cta" block> Todo Período </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card title="">
      <div v-if="loading" class="d-flex justify-content-center align-items-center" style="height: 50vh">
        <div class="spinner-border spinner-border-sm"></div>
      </div>
      <div v-show="!loading" class="mt-2">
        <b-table
          ref="mytab"
          responsive="sm"
          class="text-left"
          small
          striped
          hover
          :fields="fields"
          :items="items"
          :per-page="10"
          :current-page="0"
          :busy="isBusy"
        >
          <template v-slot:cell(title)="{ item }">
            <strong>{{ item.title }}</strong
            ><br />
            <small> {{ item.notes ? 'Obs.: ' + item.notes : 'Sem descrição' }}</small>
          </template>
          <template v-slot:cell(project)="{ item }">
            <strong>#{{  item.project.project_code }}</strong>
            <p>{{  item.project.project_name }}</p>
          </template>
          <template v-slot:cell(actions)="props">
            <div @click="handleUpdateModal(props.item)">
              <feather-icon v-b-tooltip.hover.lefttop.v-primary title="Editar fornecedor" icon="EditIcon" size="15" />Editar
            </div>
          </template>
          <template v-slot:cell(username)="{ item }">
            <div>
              <div
                :style="{
                  display: 'flex',
                  alignItems: 'center'
                }"
              >
                <span
                  :style="{
                    borderRadius: '50%',
                    //   display: 'block',
                    background: item.member.color,
                    width: '10px',
                    height: '10px'
                  }"
                >
                </span>
                <p
                  :style="{
                    height: 'auto',
                    paddingLeft: '5px',
                    paddingTop: '10px'
                  }"
                >
                  {{ item.member.access.user.username }}
                </p>
              </div>
              <small>{{ item.member.access.user.email }}</small>
            </div>
          </template>

          <template v-slot:cell(date)="{ item }">
            {{ dateFormatter(item.date, 'dd/MM/yyyy') }}
          </template>
          <template v-slot:cell(start)="{ item }">
            {{ item.start.slice(0, 5) }}
          </template>
          <template v-slot:cell(end)="{ item }">
            {{ item.end.slice(0, 5) }}
          </template>
          <template v-slot:cell(comments)="{ item }">
            {{ item.notes }}
          </template>
        </b-table>

        <b-pagination
          class="justify-content-center"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          @change="getPage"
          aria-controls="my-table"
        ></b-pagination>
        <b-col cols="12" align="end">
          <b>Total de tarefas: {{ totalRows }}</b>
        </b-col>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BPagination,
  VBTooltip,
  BCol,
  BRow,
  BFormGroup,
  BButton,
  BFormInput,
  BFormCheckbox
} from 'bootstrap-vue';

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
  directives: {
    'b-tooltip': VBTooltip
  },
  components: {
    BCard,
    BTable,
    BPagination,
    BCol,
    BRow,
    BFormGroup,
    BButton,
    BFormInput,
    BFormCheckbox
  },
  data: () => ({
    items: [],
    allowInactivates: false,
    modalNewSupplier: false,
    modalDeleteSupplier: false,
    currentPage: 1,
    isBusy: false,
    loading: true,
    perPage: 10,
    startDateWithDay: '',
    endDateWithDay: '',
    totalRows: 0,
    start_date: '',
    users: [],
    idArr: [],
    end_date: '',
    fields: [
      {
        key: 'title',
        label: 'Tarefa',
        sortable: true,
        thStyle: { width: '20%' }
      },
      {
        key: 'project',
        label: 'Projeto',
        sortable: true,
        thStyle: { width: '20%' }
      },
      {
        key: 'username',
        label: 'Responsável',
        sortable: true
      },
      {
        key: 'date',
        label: 'Data',
        sortable: true
      },
      {
        key: 'start',
        label: 'Início',
        sortable: true
      },
      {
        key: 'end',
        label: 'Fim',
        sortable: true
      }
    ],
    taxRecordsList: false
  }),
  created() {
    this.getTasks();
    this.getAllUsers();
  },

  methods: {
    getPage(val) {
      this.isBusy = true;
      this.items = [];
      this.$store
        .dispatch('getAllProjectTasks', {
          workspace_id: this.$store.getters.currentWorkspace.id,
          inactive: this.allowInactivates,
          id: this.$route.params.id,
          page: val,
          perPage: 10
        })
        .then(resp => {
          this.currentPage = val;
       
          this.totalRows = resp.pagination.total;
          this.items = resp.data;
          this.isBusy = false
          if (resp === '') {
            this.items = [];
          }
          this.$nextTick(() => {
            this.$refs.mytab.refresh();
          });
        
        })
        .catch(err => {
          console.log(err);
        });
      this.loading = false;
    },

    getTasks() {
      (this.isBusy = true),
        this.$store
          .dispatch('getAllProjectTasks', {
            workspace_id: this.$store.getters.currentWorkspace.id,
            inactive: this.allowInactivates,
            id: this.$route.params.id,
            page: this.currentPage,
            perPage: 10
          })
          .then(resp => {
            //console.log(resp);
            
            this.items = resp.data;
            this.totalRows = resp.pagination.total;
            //console.log(resp);
            this.isBusy = false;
            if (resp === '') {
              this.items = [];
            }

           
          })
          .catch(err => {
            console.log(err);
          });
      this.loading = false;
    },
    swtichAllowInactivate(e) {
      this.allowInactivates = e;
      this.idArr = [];
      this.events = [];
      this.items = [];
      this.getAllUsers();
      this.getTasks('');
    },
    async getAllUsers() {
      if (!this.userList) {
        this.$store
          .dispatch('getAllUsers', {
            workspace_id: this.$store.getters.currentWorkspace.id,
            inactive: this.allowInactivates,
            page: 1,
            perPage: 1000,
          })
          .then(resp => {
            if (resp) {
              this.users = resp.data;
            }
            this.users.forEach(user => {
              // console.log('forEach', user.member_id);
              this.idArr.push(user.member_id);
            });
            //console.log('id arr', this.idArr);
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.getInactivesUsers();
      }
    },
    async getTasksBySelect(member_id) {
      this.idArr = this.idArr.includes(member_id) ? this.idArr.filter(id => id !== member_id) : [...this.idArr, member_id];

      //console.log(this.idArr);

      this.$store
        .dispatch('getAllProjectTasks', {
          id: this.$route.params.id,
          start_date: this.startDateWithDay,
          end_date: this.endDateWithLastDay,
          member_id: this.idArr,
          workspace_id: this.$store.getters.currentWorkspace.id,
          inactive: this.allowInactivates,
          page: this.currentPage,
          perPage: 10,
          type: 'select'
        })
        .then(resp => {
   
          this.items = resp.data;
          this.totalRows = resp.pagination.total;
          console.log('resp by select',resp);
          this.events = [];
          if (resp === '') {
            this.items = [];
          }

          this.items.map(dialog => {
            this.events.push({
              ...dialog,
              project_code: dialog.project_code,
              date: dialog.date,
              startTime: this.formatTime(dialog.start),
              endTime: this.formatTime(dialog.end),
              name: dialog.title,
              comments: dialog.notes,
              username: dialog.username,
              
            });
          });
       
        })
        .catch(err => {
          console.log(err);
        });
    },
    cleanFilter() {
      this.modelTaxRecords.search = '';
    },
    applyDateFilter() {
      this.$store
        .dispatch('getAllProjectTasks', {
          workspace_id: this.$store.getters.currentWorkspace.id,
          inactive: this.allowInactivates,
          id: this.$route.params.id,
          start_date: this.startDateWithDay,
          end_date: this.endDateWithLastDay,
          page: this.currentPage,
          perPage: 10,
        })
        .then(resp => {
          this.totalRows = resp.pagination.total;
          this.items = resp.data;
          // console.log(resp);
          if (resp === '') {
            this.items = [];
          }

      
        })
        .catch(err => {
          console.log(err);
        });
    },
    async updateDates() {
      this.startDateWithDay = `${this.start_date}-01`;
      const endDateYear = parseInt(this.end_date.substring(0, 4));
      const endDateMonth = parseInt(this.end_date.substring(5, 7));

      const lastDayOfMonth = new Date(endDateYear, endDateMonth, 0).getDate();

      const formattedMonth = endDateMonth < 10 ? `0${endDateMonth}` : endDateMonth;

      this.endDateWithLastDay = `${endDateYear}-${formattedMonth}-${lastDayOfMonth}`;

      this.applyDateFilter(); // Fetch data with updated dates
    },

    openDeleteModal(item) {
      this.modalDeleteSupplier = true;
      this.$store.modalSupplier = item;
    }
  }
};
</script>
